import "./Footer.scss";

const templateFooter = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6 footer-contact">
              <img src="images/footer-logo.svg" alt="" />
              <p>
                Conecta con tus clientes, fidelízalos por siempre.
              </p>
            </div>

            <div className="col-lg-2 offset-lg-1 col-md-6 footer-links">
              <h4>Empresa</h4>
              <ul>
                <li> <a href="/">Home</a></li>
                <li> <a href="/">Usuarios</a></li>
                <li> <a href="https://medium.com/@eduardo_30817/unlocking-the-power-of-brand-loyalty-with-web3-f07300531e49">Blog</a></li>
              </ul>
            </div>

            <div className="col-lg-2 offset-lg-1 col-md-6 footer-links">
              <h4>Legal</h4>
              <ul>
                <li> <a href="legal_notice.html">Aviso legal</a></li>
                <li> <a href="privacy_policy.html">Política de Privacidad</a></li>
                <li> <a href="terms_of_use.html">Términos de Uso</a></li>
              </ul>
            </div>

            <div className="col-lg-2 offset-lg-1 col-md-6 footer-links">
              <h4>Contacto</h4>
              <ul>
                <li> <a href="mailto:Support@perkss.io" className='App__footer-contact-email'>Support@perkss.io</a></li>
                <li> <a href="tel:+34656961823" className='App__footer-contact-phone'>+34 656 96 18 23</a></li>
              </ul>
            </div>


          </div>


        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            <p>&copy; Copyright <strong><span>Perkss</span></strong>. All Rights Reserved</p>
          </div>

        </div>
        {/* <div class="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
          <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
          <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
          <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div> */}
      </div>
    </footer>
  );
};

export default templateFooter;
