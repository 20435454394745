import './App.scss';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import CookiesConsent from './components/CookiesConsent/CookiesConsent';

function App() {
  return (
    <>
      <Header/>
      <Hero/>
      <Main/>
      <Footer/>
      <CookiesConsent/>
    </>
  );
}

export default App;
