import "./Header.scss";

const templateHeader = (isNavActive, handleNavToggle) => {

  return (
    <header id="header">
      <div className="container d-flex align-items-center justify-content-between">

        <a href="index.html" className="logo"><img src="logo.svg" alt="" className="img-fluid" /></a>

        <nav id="navbar" className={`navbar ${isNavActive ? 'navbar-mobile' : ''}`}>
          <ul>
            <li><a className="nav-link scrollto active" href="/">Home</a></li>
            <li><a className="nav-link scrollto" href="/">Usuarios</a></li>
            <li><a className="nav-link scrollto" href="https://medium.com/@eduardo_30817/unlocking-the-power-of-brand-loyalty-with-web3-f07300531e49">Blog</a></li>
            <li><a className="getstarted" href="https://app.perkss.io/auth/login">Inicia Sesión</a></li>
            <li><a className="getstarted" href="https://app.perkss.io/auth/signup">Registro</a></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={handleNavToggle}></i>
        </nav>

      </div>
    </header>
  );
};

export default templateHeader;
