import templateMain from "./Main.template";
import { useEffect } from "react";

const Main = () => {
    
  useEffect(() => {
    // Would be good to use the npm package instead of the imported library.
    // This is done just to keep the migration to react (from the "vanilla" site) easier.
    if (window.Swiper) {
        new window.Swiper('.testimonials-slider', {
            speed: 600,
            loop: true,
            autoplay: false,
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                1200: {
                    slidesPerView: 1,
                    spaceBetween: 20
                }
            }
        });
        new window.Swiper('.testimonials-slider-two', {
            speed: 600,
            loop: true,
            autoplay: false,
            slidesPerView: 'auto',
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
            },
            breakpoints: {
              320: {
                slidesPerView: 1,
                spaceBetween: 20
              },
        
              1200: {
                slidesPerView: 1,
                spaceBetween: 20
              }
            }
          });
    }
}, []);

    return templateMain();
}

export default Main;