import { useState } from "react";
import templateHeader from "./Header.template";

const Header = () => {
    const [isNavActive, setIsNavActive] = useState(false);

    const handleNavToggle = () => {
      setIsNavActive(!isNavActive);
    };

    return templateHeader(isNavActive, handleNavToggle);
}

export default Header;