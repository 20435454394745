import "./Main.scss";

// This component can/should be sub divided for the different sections, no need to have them all here
const templateHeader = () => {
  return (
    <main id="main">

      <section className="beneficios">
        <div className="container">
          <div className="icon-boxes">
            <div className="row">
              <div className="col-lg-3">
                <div className="sec-heading">
                  <h5>beneficios</h5>
                  <h2>Impulsa tus acciones</h2>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row">

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch ">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/heart.svg" alt=""/>
                      </div>
                      <h4 className="title">Potencia tu relación y cuidado con los clientes</h4>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/rocket.svg" alt=""/>
                      </div>
                      <h4 className="title">Catapulta tus ratios de retención</h4>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/graph.svg" alt=""/>
                      </div>
                      <h4 className="title">Incrementa tu ticket medio, LTV y ROAS.</h4>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/magic.svg" alt=""/>
                      </div>
                      <h4 className="title">Personaliza la experiencia de compra</h4>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/user-men.svg" alt=""/>
                      </div>
                      <h4 className="title">Conoce el 100% de clientes que visitan tu tienda física</h4>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/magnifier.svg" alt=""/>
                      </div>
                      <h4 className="title">Analiza, automatiza, optimiza. Reduce tus CACs</h4>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/Chat.svg" alt=""/>
                      </div>
                      <h4 className="title">Comunícate con tus clientes de forma efectiva.</h4>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                    <div className="icon-box">
                      <div className="icon">
                        <img src="images/graph.svg" alt=""/>
                      </div>
                      <h4 className="title">Reduce carga de trabajo a tu equipo de marketing</h4>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="como" className="como">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 text-end pull-right order-lg-2">
              <img src="images/Como.svg" className="img-fluid" alt=""/>
            </div>
            <div className="col-lg-6 pull-left order-lg-1">
              <div className="sec-heading">
                <h5>Como retailers, entendemos tus problemas</h5>
                <h2>Es hora de repensar la forma en la que fidelizamos y adquirimos clientes</h2>
              </div>
              <p>
                Retener clientes es cada vez más difícil, adquirir nuevos es sumamente costoso. <b>Llegó el momento de
                  invertir más en
                  nuestros clientes</b>, en su experiencia y en la relación con la marca, y menos en Google, Instagram,
                Facebook, y en
                programas de fidelidad tradicionales que no funcionan.
              </p>
              <a href="/"><button className="btn-learn-more">Descubre cómo</button></a>
            </div>
          </div>
        </div>
      </section>


      <section id="services" className="services">
        <div className="container">
          <div className="text-center sec-heading mb-5">
            <h5>Un antes y un después</h5>
            <h2 className="fw-bold">Impulsando la ROASvolución</h2>
          </div>
          <div className="row">

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <img src="images/heart.svg" alt=""/>
                <h4>Fidelidad</h4>
                <p>Gamifica y personaliza la experiencia de compra, crea tu propio euro de fidelidad, garantiza
                  valor.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <img src="images/video.svg" alt=""/>
                <h4>Anuncios</h4>
                <p>Olvídate de pagar por clicks. En Perkss, solo pagarás cuando tus clientes compren. 100% gratis hasta
                  convertir.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <img src="images/graph.svg" alt=""/>
                <h4>Datos</h4>
                <p> Accede a los tres datos de consumo más importantes: dónde, cuánto y con qué frecuencia ha comprado un
                  consumidor.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <img src="images/lock-line.svg" alt=""/>
                <h4>Privacidad</h4>
                <p>Fomenta la privacidad de tus clientes sin sacrificar la comunicación directa.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <img src="images/Chat.svg" alt=""/>
                <h4>Comunicación</h4>
                <p>Ten la posibilidad de conectar con el 100% de tus clientes, no sólo los que se suscriben a tu
                  newsletter.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <img src="images/Eye.svg" alt=""/>
                <h4>Visitas</h4>
                <p>Entérate de todos los clientes que visitan tu tienda física de forma sencilla.</p>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section id="testimonials" className="antes">
        <div className="container">
          <div className="sec-heading text-center mt-0">
            <h5>Un antes y un después</h5>
            <h2 className="text-white mt-3">Cómo funciona</h2>
          </div>
          <ul className="nav nav-pills my-5 justify-content-center" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                type="button" role="tab" aria-controls="pills-home" aria-selected="true">Retención y Fidelización</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Adquisición</button>
            </li>
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="testimonials-slider swiper">
                <div className="swiper-wrapper">

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>1</h2>
                      <p>Crea tu propio Euro de Fidelidad y otras recompensas Ecomocionales (económicas + emocionales).
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>2</h2>
                      <p>Establece el objetivo de consumo que deben alcanzar tus clientes.
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>3</h2>
                      <p>Determina las recompensas que darás a tus clientes tras alcanzar el objetivo de consumo y al
                        realizar acciones que
                        promuevan tu marca.
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>4</h2>
                      <p>Analiza, optimiza y personaliza objetivos y recompensas
                      </p>
                    </div>
                  </div>

                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div className="testimonials-slider-two  swiper">
                <div className="swiper-wrapper">

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>1</h2>
                      <p>Crea productos y/o sube contenido
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>2</h2>
                      <p>Crea audiencias hiper Segmentadas
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>3</h2>
                      <p>Configura y lanza campañas
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <h2>4</h2>
                      <p>Analiza, optimiza, personaliza ofertas y publicaciones.
                      </p>
                    </div>
                  </div>

                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="como" className="como">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 text-end pull-right order-lg-2">
              <img src="images/ai.webp" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 pull-left order-lg-1">
              <div className="sec-heading">
                <h5>La mejor infraestructura</h5>
                <h2>Impulsados por Blockchain e IA</h2>
              </div>

              <p>En Perkss, fusionamos Blockchain y la IA para superar los retos de fidelización y adquisición de clientes
                actuales.</p>
              <p>La Blockchain nos permite ofrecer programas de fidelidad transparentes e interoperables, mientras que
                gracias a la IA
                personalizamos la experiencia de cada cliente y optimizamos las campañas de marketing.
              </p>
              <p>
                Esta sinergia tecnológica se traduce en la mejor experiencia posible para tus clientes y en soluciones de
                fidelización
                altamente efectivas y personalizadas para ti. Con Perkss, la ROASvolución está a tu alcance.
              </p>
              <p>
                Lo mejor, ni tú ni tus clientes sabrán que estarán interactuando con estas tecnologías. Garantizamos una
                experiencia de
                consumidor del más alto nivel.
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className="cambio">
        <div className="container">
          <div className="boxes">
            <div className="sec-heading text-center mb-5">
              <h5>Un cambio de paradigma</h5>
              <h2>Una plataforma diferente para resolver <br/> los verdaderos problemas de los retailers</h2>
            </div>
            <div className="row mt-5">

              <div className="col-md-4 col-lg-2 offset-lg-1 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img src="images/rocket.svg" alt=""/>
                  </div>
                  <h4 className="title">No requiere integraciones</h4>
                </div>
              </div>

              <div className="col-md-4 col-lg-2 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img src="images/rocket.svg" alt=""/>
                  </div>
                  <h4 className="title">No pagarás hasta que veas resultados</h4>
                </div>
              </div>

              <div className="col-md-4 col-lg-2 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img src="images/graph.svg" alt=""/>
                  </div>
                  <h4 className="title">En 5 minutos puedes comenzar a usarlo</h4>
                </div>
              </div>

              <div className="col-md-4 col-lg-2 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img src="images/magic.svg" alt=""/>
                  </div>
                  <h4 className="title">No tiene coste de mantenimiento</h4>
                </div>
              </div>

              <div className="col-md-4 col-lg-2 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img src="images/graph.svg" alt=""/>
                  </div>
                  <h4 className="title">No requiere conocimientos técnicos</h4>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


      <section className="redefine">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h5>Redefine las reglas</h5>
              <h2>¿Estás listo para transformar la fidelización de tus clientes?</h2>
              <a href="/" className="btn-get-started">Comenzar</a>
            </div>
          </div>
        </div>
      </section>

    </main>
  );
};

export default templateHeader;
