import "./Hero.scss";

const templateHero = (text, to) => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6">
            <img src="images/banner-img.png" className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <h1>Conecta con tus clientes, fidelízalos por siempre.</h1>
            <p>Te presentamos <b>el primer programa de fidelidad que sí funciona</b>. Una plataforma de retención y
              adquisición
              de clientes
              diseñada para ti, marca del futuro.</p>

            <div className="">
              <a href="https://app.perkss.io/auth/signup" className="btn-get-started">Registro</a>
              <a href="https://calendly.com/eduardosg" className="btn-get-started">Agendar llamada</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default templateHero;
